/** @jsx jsx */
import { string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'
import EmailSubscribeForm from '~/components/EmailSubscribeForm'

import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderRichText from '~/content/renderRichText'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'EmailSubscribeSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType,
  elementId: string,
  errorText: string,
  heading: string,
  successText: string,
}

const Component = ({ body, elementId, errorText, heading, successText }) => (
  <Section id={elementId}>
    <Container variant="containerSmall">
      <IntroTransition>
        <Heading
          as="h2"
          variant="heading1"
          mb={1}
        >
          {heading}
        </Heading>
        <div sx={{ mb: 3 }}>
          {renderRichText(body, { style: 'prospect' })}
        </div>
        <EmailSubscribeForm
          successText={successText}
          errorText={errorText}
        />
      </IntroTransition>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
